// material-ui
import { Typography } from '@mui/material';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import BidListTable from 'ui-component/BidListTable';
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import BASE_URL from '../../../baseurl'
import { fetchDash } from 'utils/bids-routes';

// ==============================|| SAMPLE PAGE ||============================== //
const BidList = () => {
    const [bids, setbids] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setcount] = useState();
    const FetchData = useCallback(() => {
        fetchDash(page, rowsPerPage, setbids,setcount)
    }, []);

    useEffect(() => {
        FetchData();
    }, [FetchData]);
    return (
        <MainCard title="Bid List">
            <BidListTable
                bids={bids}
                FetchData={FetchData}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                count={count}
                setbids={setbids}
                setcount={setcount}
            />
        </MainCard>
    );
};

export default BidList;
