import { toHaveStyle } from '@testing-library/jest-dom/dist/matchers';
import { toast } from 'react-toastify';
import axios from './axios-server';

// for toast
// import { toast } from "react-toastify";
// toast.configure(); // toast-configuration method,

export const fetchUsersList = function (page, rowsPerPage, setusers, setcount) {
    return axios
        .get(`/api/user/paginated-users?page=${page}&limit=${rowsPerPage}`)
        .then((res) => {
            setusers(res.data.data.rows);
            setcount(res.data.data.count);
        })
        .catch((err) => {
            console.log(err);
        });
};

export const filterUsersByQuery = function (page, rowsPerPage, query, setState, setcount) {
    return axios
        .post(`/api/user/find?page=${page}&limit=${rowsPerPage}`, {
            queryData: query
        })
        .then((res) => {
            setState(res.data.data.rows);
            setcount(res.data.data.count);
        })
        .catch((err) => {
            console.log(err);
        });
};

export const changeUserStatus = function (data, fetchData) {
    return axios
        .post(`/api/user/update-2`, data)
        .then((res) => {
            toast.success(res.data.message,{position:toast.POSITION.TOP_RIGHT})
            fetchData();
        })
        .catch((err) => {
            console.log(err);
        });
};

export const editUser = function (data, FetchData, setloading, setOpen) {
    return axios
        .post(`/api/user/update-2`, {
            first_name: data.Firstname,
            last_name: data.lastName,
            email: data.email,
            address: data.address,
            phoneno: data.phoneno,
            id: data.user.id,
            accreditation_verified: data.accreditation_verified,
            account_status: data.account_status,
            date_of_birth: data.date_of_birth
        })
        .then((res) => {
            toast.success(res.data.message,{position:toast.POSITION.TOP_RIGHT})
            FetchData();
            setloading(false);
            setOpen(false);
        })
        .catch((err) => {
            console.log("errr=========",err);
            setloading(false);
            setOpen(false);
        });
};

export const deleteUser = function (id, FetchData, setOpen) {
    return axios
        .delete(`/api/user/delete-user/${id}`)
        .then((res) => {
            toast.success(res.data.message,{position:toast.POSITION.TOP_RIGHT})
            FetchData();
            setOpen(false);
        })
        .catch((err) => {
            console.log(err);
            setOpen(false);
        });
};

