import axios from 'axios';
import { toast } from 'react-toastify';
import BASE_URL from '../baseurl';

const instance=axios.create({
    baseURL:BASE_URL
    
})
if (localStorage.token) {
    instance.defaults.headers.common["x-auth-token"] = localStorage.token;
  } else {
    delete instance.defaults.headers.common["x-auth-token"];
  }

instance.interceptors.response.use(
  (response) => {
    
    if (response.status===200) {
      // localStorage.clear()
      // window.location.reload()
    }
    return response;
  },
  // eslint-disable-next-line arrow-body-style
  (error) => {
    if(error.response.status===401 && error.response.meta==="invalid_token")
   toast.error(error.response.data.message,{position:toast.POSITION.TOP_RIGHT})

    localStorage.clear()
      window.location.reload()
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default instance;
