// material-ui
import { Typography } from '@mui/material';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import IssuanceInvesterListTable from 'ui-component/IssuanceInvesterListTable';
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import BASE_URL from '../../../baseurl'
import { fetchInvesterList } from 'utils/invester-issuance-routes';
import { useParams } from 'react-router-dom';
// ==============================|| SAMPLE PAGE ||============================== //
const InvesterIssuance = () => {
    const [investerIssuance, setinvesterIssuance] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setcount] = useState();
    const { id } = useParams();

    const FetchData = useCallback(() => {
        fetchInvesterList(page, rowsPerPage, setinvesterIssuance,setcount,id)
    }, []);

    useEffect(() => {
        FetchData();
    }, [FetchData]);

    return (
        <MainCard title="Invester Issuance List">
            <IssuanceInvesterListTable
                investerIssuance={investerIssuance}
                FetchData={FetchData}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                count={count}
                setinvesterIssuance={setinvesterIssuance}
                setcount={setcount}
            />
        </MainCard>
    );
};

export default InvesterIssuance;
