import React, { useState, useEffet } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import { Grid, Box } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { editBid } from 'utils/bids-routes';

export default function EditBid({ bid, FetchData }) {
    const [loading, setloading] = useState(false);
    const [open, setOpen] = useState(false);
    const [array, setarray] = useState([]);
    const [value, setValue] = useState('');
    const [amountOffered, setAmountOffered] = useState(bid?.amount_offered);
    const [Yield, setYield] = useState(bid?.yield);
    const [AmountInvesting, setAmountInvesting] = useState(bid?.amount_investing);
    const [status, setstatus] = useState(bid?.status);
    const id = bid.id
    const handleChange = (event) => {
        setstatus(event.target.value);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        setloading(true);
        editBid(amountOffered, Yield, setloading, setOpen, FetchData, status, AmountInvesting,bid,id);
    };
  
    return (
        <div>
            <IconButton onClick={handleClickOpen}>
                <EditIcon />
            </IconButton>
            <Dialog fullWidth open={open} onClose={handleClose}>
                <DialogTitle sx={{ fontWeight: 'bold' }}>Edit Bid</DialogTitle>
                <DialogContent>
                    <Grid sx={{ marginTop: 1 }} container spacing={2}>
                        <Grid item lg={4} xs={12}>
                            <TextField
                                value={amountOffered}
                                onChange={(e) => setAmountOffered(e.target.value)}
                                id="outlined-basic"
                                label="Amount Offered"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item lg={4} xs={12}>
                            <TextField
                                value={Yield}
                                onChange={(e) => setYield(e.target.value)}
                                id="outlined-basic"
                                label="Yield"
                                variant="outlined"
                                type="number"
                            />
                        </Grid>
                        <Grid item lg={4} xs={12}>
                            <TextField
                                value={AmountInvesting}
                                onChange={(e) => setAmountInvesting(e.target.value)}
                                id="outlined-basic"
                                label="Amount Investing"
                                variant="outlined"
                            />
                        </Grid>
                        {/* <Grid item lg={4} xs={12}>
                            <TextField
                                value={code}
                                onChange={(e) => setCode(e.target.value)}
                                id="outlined-basic"
                                label="Code"
                                variant="outlined"
                            />
                        </Grid> */}

                        <Grid item lg={4} xs={12}>
                            <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={status ?? bid?.status}
                                        label="Status"
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="Won">Won</MenuItem>
                                        <MenuItem value="Lost">Lost</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>{' '}
                        </Grid>
                       
                       
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSubmit}>Update</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
