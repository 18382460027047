// material-ui
import { Typography } from '@mui/material';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import AuctionTable from 'ui-component/AuctionTable';
// ==============================|| SAMPLE PAGE ||============================== //
const AuctionList = () => (
    <MainCard title="Auction List">
        <AuctionTable />
    </MainCard>
);

export default AuctionList;
