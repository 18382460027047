import axios from 'axios';

const changeStatus = (url, data,fetchData) => {
    axios
        .post(`${url}`,data)
        .then((res) => {
            fetchData()
        })
        .catch((err) => {
            console.log(err);
        });
};

export default changeStatus;
