import axios from './axios-server';
import BASE_URL from 'baseurl';
import { toast } from 'react-toastify';

const editAuction = (amountRaising, maxYield, contractLength, setloading, setOpen, FetchData, status, code, startDate,EndDate,auction,id) => {
    axios
        .post(`/api/auction/edit-auction`, {
            id,
            amount_raising: amountRaising,
            max_yield:maxYield,
            contract_length:contractLength,
            start_date: startDate,
            end_date: EndDate,
            status: status ?? auction?.status,
            code
        })
        .then((res) => {
            toast.success(res.data.message,{position:toast.POSITION.TOP_RIGHT})
            FetchData();
            setloading(false);
            setOpen(false);
        })
        .catch((err) => {
            console.log(err);
            setloading(false);
            setOpen(false);
        });
};

export default editAuction;
