import axios from 'axios';

const changePropertyStatus = (url, data,fetchData) => {
    axios
        .patch(`${url}`,data)
        .then((res) => {
            fetchData()
        })
        .catch((err) => {
            console.log(err);
        });
};

export default changePropertyStatus;
