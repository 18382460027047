import axios from './axios-server';

export const fetchIssuance = async function(page, rowsPerPage, setIssuance)  {
   return axios
        .get(`/api/issuance/issuanceowner-list?page=0&limit=10`)
        .then((res) => {
            setIssuance(res.data.data.rows);
        })
        .catch((err) => {
            console.log(err);
        });
};

export const fetchpayments2 = async  function (page, rowsPerPage, setpayments,setcount) {
    return axios
         .get(`/api/payment-made/list-payments?page=${page}&limit=${rowsPerPage}`, {})
         .then((res) => {
            setpayments(res.data.data.rows);
            setcount(res.data.data.count);
         })
         .catch((err) => {
             console.log(err);
         });
 };


 export const makePayments = async  function (fetchData , data,handleClose) {
    return axios
         .post(`/api/payment-made/create-payment`, data)
         .then((res) => {
            fetchData()
            handleClose()
         })
         .catch((err) => {
             console.log(err);
             handleClose()
         });
 };