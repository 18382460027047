import commaNumber from "comma-number";

export const floatingCurrency = (num) => {
	const strIntoNum = parseFloat(Number.isNaN(num) || num === null ? 0 : num  ,10).toFixed(2)
    const usdCurrency = commaNumber(strIntoNum)
    return  usdCurrency
}

export const Currency = (num) => {
	const strIntoNum = parseInt(Number.isNaN(num) || num === null ? 0 : num  , 10)
    const usdCurrency = commaNumber(strIntoNum)
    return usdCurrency
}
 