// material-ui
import { Typography } from '@mui/material';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import BidListTable from 'ui-component/BidListTable';
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import BASE_URL from '../../../baseurl';
import { fetchDashBidId } from 'utils/bids-routes';

// ==============================|| SAMPLE PAGE ||============================== //
const AuctionBid = () => {
    const { id } = useParams();
    const [bids, setbids] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setcount] = useState();

    const FetchData = useCallback(() => {
        fetchDashBidId(page, rowsPerPage, setbids, setcount, id);
    }, []);

    useEffect(() => {
        FetchData();
    }, [FetchData]);
    return (
        <MainCard title="These are Bids for your Selected Auction">
            <BidListTable
                bids={bids}
                FetchData={FetchData}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                count={count}
                setbids={setbids}
                setcount={setcount}
            />
        </MainCard>
    );
};

export default AuctionBid;
