import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import TableHead from '@mui/material/TableHead';
// import Editissuance from './Editissuance';
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Currency, floatingCurrency } from 'utils/currency';
// import DeleteDailog from './DeleteModal';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Grid } from '@mui/material';
import filterIssuance from 'utils/filterIssuance';
import Button from '@mui/material/Button';
import BASE_URL from 'baseurl';
import changePropertyStatus from 'utils/changePropertyStatus';
import changeStatus from 'utils/changeStatus';
import { Link } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const fetchissuance = async (page, limit) => {
    const newPage = page;
    const res = await fetch(`${BASE_URL}/api/issuance/issuanceowner-list?page=${newPage}&limit=${limit}`);
    const data = await res.json();
    return data.data.rows;
};

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired===undefined??"",
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired
};

export default function PropertyDataTable() {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [status, setstatus] = useState('');
    const [issuance, setissuance] = useState([]);
    const [count, setcount] = useState();

    const filterObj = {
        status
    };
    const filterHandler = () => {
        filterIssuance(`${BASE_URL}/api/issuance/filter-by-status?page=${page}&limit=${rowsPerPage}`, filterObj, setissuance);
    };

    const FetchData = useCallback(() => {
        axios
            .get(`${BASE_URL}/api/issuance/issuanceowner-list?page=${page}&limit=${rowsPerPage}`)
            .then((res) => {
                setissuance(res.data.data.rows);
                setcount(res.data.data.count);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    useEffect(() => {
        FetchData();
    }, [FetchData]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        fetchissuance(newPage, rowsPerPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handlerStatusChange = (e, id) => {
        e.preventDefault();
        const data = {
            id,
            status: e.target.value
        };
        changeStatus(`${BASE_URL}/api/issuance/edit-iussance`, data, FetchData);
    };

    return (
        <div>
            <Grid container spacing={2} display="flex" justifyContent="flex-end" alignItems="flex-end">
                <Grid item lg={3} sx={0} />
                <Grid item lg={3} sx={0} />
                <Grid item lg={3} sx={0} />
                <Grid item lg={2} md={3} xs={12}>
                    <Box>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={status}
                                label="status"
                                onChange={(e) => setstatus(e.target.value)}
                            >
                                <MenuItem value="Complete">Complete</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>{' '}
                </Grid>
                <Grid item lg={1} md={4} xs={12}>
                    <Button onClick={filterHandler} sx={{ height: 50 }} variant="contained">
                        Filter
                    </Button>
                </Grid>
            </Grid>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Auction Id</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>yield</TableCell>
                            <TableCell>contract_length</TableCell>
                            {/* <TableCell>Address</TableCell> */}
                            <TableCell> Appraised Value</TableCell>
                            <TableCell>Hpi Id </TableCell>
                            <TableCell>Hpi Value</TableCell>
                            <TableCell>Initial Hpi</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>See Investers</TableCell>
                            {/* <TableCell>Action</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {issuance?.map((issuance) => (
                            <TableRow key={issuance.id}>
                                <TableCell>
                                    {moment(issuance?.createdAt).isValid() ? moment(issuance?.createdAt).format('MMM DD, YYYY') : '-'}
                                </TableCell>

                                {/* <TableCell>{moment(issuance.createdAt).format('DD MM YYYY')}</TableCell> */}
                                <TableCell>{issuance.auction_id}</TableCell>
                                <TableCell>{issuance.amount}</TableCell>
                                <TableCell>{floatingCurrency(issuance.yield)}%</TableCell>
                                <TableCell>{issuance?.contract_length}</TableCell>
                                <TableCell>{issuance?.appraised_value}</TableCell>
                                <TableCell>{issuance?.hpi_id}</TableCell>
                                <TableCell>{issuance?.hpi_value}</TableCell>
                                <TableCell>{issuance?.initial_hpi_value_id}</TableCell>
                                <TableCell>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={issuance?.status}
                                            label="Status"
                                            onChange={(e) => handlerStatusChange(e, issuance.id)}
                                        >
                                            <MenuItem value="Complete">Complete</MenuItem>
                                        </Select>
                                    </FormControl>
                                </TableCell>
                                <TableCell>
                                    <Link to={`/admin/invester-issuance/${issuance.id}`}>
                                        <ArrowForwardIcon />
                                    </Link>
                                </TableCell>

                                {/* <TableCell >{issuance?.code}</TableCell> */}
                                {/* <TableCell sx={{ display: 'flex' ,padding:4 }}> */}
                                {/* <Editissuance issuance={issuance} FetchData={FetchData} />
                                    <DeleteDailog
                                        url={`${BASE_URL}/api/property/delete-property`}
                                        id={issuance.id}
                                        item="Property"
                                        FetchData={FetchData}
                                    /> */}
                                {/* </TableCell> */}
                            </TableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                             style={{float:'left'}}
                                rowsPerPageOptions={[]}
                                colSpan={3}
                                count={count}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'rows per page'
                                    },
                                    native: true
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </div>
    );
}
