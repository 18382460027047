import axios from './axios-server';

export const fetchDash = async (page, limit, setwithdraws) => {
    const res = await fetch(`/api/withdraw/record?page=${page}&limit=${limit}`);
    const data = await res.json();
    setwithdraws(data.data.rows);
    return data.data.rows;
};

export const  fetchwithdraw = async (page, rowsPerPage, setwithdraws, setcount) => {
      axios
        .get(`/api/withdraw/record?page=${page}&limit=${rowsPerPage}`)
        .then((res) => {
            setwithdraws(res.data.data.rows);
            setcount(res.data?.data?.count);
        })
        .catch((err) => {
            console.log(err);
        });
};