import axios from './axios-server';

export const fetchInvesterList = function (page, rowsPerPage, setInvesterIssuance, setcount,id) {
    return axios
        .post(`/api/invester-iussance/list?page=${page}&limit=${rowsPerPage}`,{issuance_id : id})
        .then((res) => {
            setInvesterIssuance(res.data.rows);
            setcount(res.data.count);
        })
        .catch((err) => {
            console.log(err);
        });
};

export const fetchInvesterList2 = function (page, rowsPerPage, setInvesterIssuance, setcount) {
    return axios
        .post(`/api/invester-iussance/list?page=${page}&limit=${rowsPerPage}`,{})
        .then((res) => {
            setInvesterIssuance(res.data.data.rows);
            setcount(res.data.count);
        })
        .catch((err) => {
            console.log(err);
        });
};

