import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import TableHead from '@mui/material/TableHead';
import EditAuctions from './EditAuctions';
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import moment from 'moment';
import { floatingCurrency, Currency } from 'utils/currency';
import DeleteDailog from './DeleteModal';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Grid, Typography } from '@mui/material';
import BASE_URL from 'baseurl';
import { fetchAuctionsList, statusFilterAuction, changeAuctionStatus } from 'utils/auction-routes';

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired===undefined??"",
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired
};

export default function AuctionDataTable() {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [auctions, setauctions] = useState([]);
    const [status, setstatus] = useState('');
    const [count, setcount] = useState();

    const FetchData = useCallback(() => {
        fetchAuctionsList(page, rowsPerPage, setauctions, setcount);
    }, []);

    useEffect(() => {
        FetchData();
    }, [FetchData]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        fetchAuctionsList(newPage, rowsPerPage, setauctions, setcount);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilter = (val) => {
        setstatus(val);
        const statusObj = {
            status: val
        };
        statusFilterAuction(page, rowsPerPage, statusObj, setauctions);
    };

    const handlerStatusChange = (e, id, ownerId) => {
        e.preventDefault();
        const data = {
            id,
            status: e.target.value,
            owner_id : ownerId
        };
        changeAuctionStatus(data, FetchData);
    };
    return (
        <div>
            <Grid container spacing={2}>
                <Grid item lg={3} md={0} xs={0} />
                <Grid item lg={3} md={0} xs={0} />
                <Grid item lg={3} md={0} xs={0} />
                <Grid item lg={3} md={3} xs={12}>
                    <Box display="flex" justifyContent="flex-end">
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={status}
                                label="status"
                                onChange={(e) => handleFilter(e.target.value)}
                            >
                                <MenuItem value="Success">Success</MenuItem>
                                <MenuItem value="failed">Failed</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>{' '}
                </Grid>
                {/* <Grid item lg={2} md={4} xs={12}>
                    <Button  sx={{ height: 50 }} variant="contained">
                        Filter
                    </Button>
                </Grid> */}
            </Grid>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Owner</TableCell>
                            {/* <TableCell>Email</TableCell> */}
                            <TableCell>Property</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>Yield</TableCell>
                            <TableCell>Contract Length</TableCell>
                            <TableCell> Start Date</TableCell>
                            <TableCell>End Date</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Action</TableCell>
                            <TableCell>See Bids</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {auctions.map((auctions) => (
                            <TableRow key={auctions.id}>
                                <TableCell>
                                    {moment(auctions?.createdAt).isValid() ? moment(auctions?.createdAt).format('MMM DD, YYYY') : '-'}
                                </TableCell>
                                <TableCell>{`${auctions?.user?.first_name===((undefined)||(null))?"-":auctions?.user?.first_name} ${auctions?.user?.last_name===((undefined)||(null))?"-":auctions?.user?.last_name}`}</TableCell>
                                {/* <TableCell>{auctions?.user?.email}</TableCell> */}
                                <TableCell>{auctions?.property?.property_address}</TableCell>
                                <TableCell>${Currency(auctions?.amount_raising)}</TableCell>
                                <TableCell>{floatingCurrency(auctions.max_yield * 100)}%</TableCell>
                                <TableCell>{auctions?.contract_length}</TableCell>
                                <TableCell>
                                    {moment(auctions?.start_date).isValid() ? moment(auctions?.start_date).format('MMM DD, YYYY') : '-'}
                                </TableCell>
                                <TableCell>
                                    {moment(auctions?.end_date).isValid() ? moment(auctions?.end_date).format('MMM DD, YYYY') : '-'}
                                </TableCell>
                                <TableCell>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={auctions?.status}
                                        label="Status"
                                        onChange={(e) => handlerStatusChange(e, auctions.id, auctions.owner_id)}
                                    >
                                        <MenuItem value="Success">Success</MenuItem>
                                        <MenuItem value="failed">Failed</MenuItem>
                                        <MenuItem value="Inactive">In Active </MenuItem>
                                        <MenuItem value="Active">Active </MenuItem>
                                        <MenuItem value="Under_Review">Under Review </MenuItem>
                                    </Select>
                                </TableCell>
                                <TableCell sx={{ display: 'flex', padding: 4 }}>
                                    <EditAuctions auction={auctions} FetchData={FetchData} />
                                    <DeleteDailog
                                        url={`${BASE_URL}/api/auction/delete-auction`}
                                        id={auctions.id}
                                        item="Auction"
                                        FetchData={FetchData}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Link to={`/admin/bids-list-Auction/${auctions.id}`}>
                                        <ArrowForwardIcon />
                                    </Link>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[]}
                                colSpan={3}
                                count={count}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'rows per page'
                                    },
                                    native: true
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </div>
    );
}
