// assets
import { IconKey, IconTypography, IconPalette, IconShadow, IconWindmill, IconHome2, IconUser, IconReportMoney ,IconHandStop,IconCash} from '@tabler/icons';

// constant
const icons = {
    IconKey,
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconHome2,
    IconUser,
    IconReportMoney,
    IconHandStop,
    IconCash
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'pages',
    // title: 'Pages',
    // caption: 'Pages Caption',
    type: 'group',
    children: [
        // {
        //     id: 'util-shadow',
        //     title: 'Shadow',
        //     type: 'item',
        //     url: '/utils/util-shadow',
        //     icon: icons.IconShadow,
        //     breadcrumbs: false
        // },
        {
            id: 'user-list',
            title: 'User List',
            type: 'item',
            url: '/admin/users-list',
            icon: icons.IconUser,
            breadcrumbs: false
        },
        {
            id: 'properties-list',
            title: 'Properties List',
            type: 'item',
            url: '/admin/properties-list',
            icon: icons.IconHome2,
            breadcrumbs: false
        },
        {
            id: 'auction-list',
            title: 'Auction List',
            type: 'item',
            url: '/admin/auction-list',
            icon: icons.IconReportMoney,
            breadcrumbs: false
        },
        {
            id: 'bid-list',
            title: 'Bids List',
            type: 'item',
            url: '/admin/bids-list',
            icon: icons.IconHandStop,
            breadcrumbs: false
        },
        {
            id: 'admin/Issuance',
            title: 'Issuances List',
            type: 'item',
            url: '/admin/Issuance',
            icon: icons.IconHandStop,
            breadcrumbs: false
        },
        {
            id: 'admin/payments',
            title: 'Payment List',
            type: 'item',
            url: '/admin/payments',
            icon: icons.IconCash,
            breadcrumbs: false
        },
        {
            id: 'admin/withdraws',
            title: 'Withdraws List',
            type: 'item',
            url: '/admin/withdraws',
            icon: icons.IconCash,
            breadcrumbs: false
        }
        // {
        //     id: 'util-typography',
        //     title: 'Typography',
        //     type: 'item',
        //     url: '/utils/util-typography',
        //     icon: icons.IconTypography,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'util-color',
        //     title: 'Color',
        //     type: 'item',
        //     url: '/utils/util-color',
        //     icon: icons.IconPalette,
        //     breadcrumbs: false
        // }
    ]
};

export default pages;
