import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import { Grid, Typography, Box } from "@mui/material";
import Chip from "@mui/material/Chip";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { editUser } from "utils/users-routes";
import { fetchIssuance, makePayments } from "utils/issuance-routes";
import moment from "moment";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

// import * as yup from "yup";
export default function AddPaymentModal({ FetchData }) {
  const [loading, setloading] = useState(false);
  const [open, setOpen] = useState(false);
  const [issuancecode, setissuancecode] = useState();
  const [paymentmethod, setpaymentmethod] = useState();

  const [issuance, setIssuance] = useState([]);

  const [createdAt, setcreatedAt] = useState(moment().format());

  useEffect(() => {
    fetchIssuance(1, 100, setIssuance);
  }, []);

  const handleChange = (event, setState) => {
    setState(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    setloading(true);
  };


  const createPaymentValidation = Yup.object().shape({
    amount: Yup.string().required("Amount is required"),
    paymentmethod: Yup.string().required("Select the payment method"),
    issuance: Yup.string().required("Select the issuance id ")
  });

  const initialValues = {
    amount: "",
    paymentmethod: "",
    issuance: ""
  };
  const onlyNumberAllow=(e)=>{
    const re=/[0-9]+/g;
    if(!re.test(e.key)){
      e.preventDefault()
    }
  }

  return (
    <div>
      <Button onClick={handleClickOpen} sx={{ mb: 2 }} variant="contained">
        Create Payment
      </Button>

      <Dialog fullWidth open={open} onClose={handleClose}>
        <Formik
          initialValues={initialValues}
          validationSchema={createPaymentValidation}
          onSubmit={(values) => {
            const data = {
              issuance_id: values.issuance,
              payment_method: values.paymentmethod,
              amount: values.amount,
              payment_date: new Date(createdAt)
            };

            makePayments(FetchData, data, handleClose);
          }}>
          {(props) => (
            <Form>
              <DialogTitle sx={{ fontWeight: "bold" }}>Add Payment</DialogTitle>

              <DialogContent>
                <Grid sx={{ marginTop: 1 }} container spacing={2}>
                  <Grid item lg={4} xs={12}>
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Issuance Code</InputLabel>

                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={issuancecode}
                          label="Issuance Code"
                          name="issuance"
                          onChange={props.handleChange}>
                          {issuance &&
                            issuance.map((data, index) => (
                              <MenuItem key={index} value={data.id}>
                                {data.code}
                              </MenuItem>
                            ))}
                        </Select>
                        <ErrorMessage
                          component="div"
                          name="issuance"
                          className="custom-invalid-feedback"
                        />
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item lg={4} xs={12}>
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Payment Method</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={paymentmethod}
                          label="Payment Method"
                          name="paymentmethod"
                          onChange={props.handleChange}>
                          <MenuItem value="" label="select" />
                          <MenuItem value="Wire" label="Wire">
                            Wire
                          </MenuItem>
                          <MenuItem value="ACH">ACH</MenuItem>
                          <MenuItem value="Cheque">Cheque</MenuItem>
                        </Select>
                        <ErrorMessage
                          component="div"
                          name="paymentmethod"
                          className="custom-invalid-feedback"
                        />
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item lg={4} xs={12}>
                    <FormControl fullWidth>
                      <TextField
                        id="amount"
                        label="amount"
                        variant="outlined"
                        name="amount"
                        onChange={props.handleChange}
                        onKeyPress={(e)=>onlyNumberAllow(e)}
                      />

                      {props.errors.amount && props.touched.amount && (
                        <ErrorMessage
                          component="div"
                          name="amount"
                          className="custom-invalid-feedback"
                        />
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item lg={4} xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="Payment Date"
                        value={createdAt}
                        onChange={(newValue) => {
                          setcreatedAt(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="submit">Submit Payment</Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
}
