import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuctionList from 'views/pages/AdminPages/AuctionList';
import BidList from 'views/pages/AdminPages/BidsList';
import AuctionBid from 'views/pages/AdminPages/AuctionBid';
import Issuance from 'views/pages/AdminPages/Issuances';
import Payment from 'views/pages/AdminPages/Payments';
import InvesterIssuance from 'views/pages/AdminPages/InvesterIssuance';
import WithDrawsList from 'views/pages/AdminPages/WithdrawList';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));
const UserList = Loadable(lazy(() => import('views/pages/AdminPages/UserList')));
const PropertyList = Loadable(lazy(() => import('views/pages/AdminPages/PropertyList')));
const LoginRoutes = Loadable(lazy(() => import('views/pages/authentication/auth-forms/AuthLogin')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: '/dashboard/default',
            element: <DashboardDefault />
        },
        {
            path: '/utils/util-typography',
            element: <UtilsTypography />
        },
        {
            path: '/utils/util-color',
            element: <UtilsColor />
        },
        {
            path: '/utils/util-shadow',
            element: <UtilsShadow />
        },
        {
            path: '/icons/tabler-icons',
            element: <UtilsTablerIcons />
        },
        {
            path: '/admin/users-list',
            element: <UserList />
        },
        {
            path: '/admin/properties-list',
            element: <PropertyList />
        },
        {
            path: '/admin/auction-list',
            element: <AuctionList />
        },
        {
            path: '/admin/bids-list',
            element: <BidList />
        },
        {
            path: '/admin/bids-list-Auction/:id',
            element: <AuctionBid />
        },
        {
            path: '/admin/Issuance/',
            element: <Issuance />
        },
        {
            path: '/admin/payments/',
            element: <Payment />
        },
        {
            path: '/admin/withdraws/',
            element: <WithDrawsList />
        },
        {
            path: '/admin/invester-issuance/:id',
            element: <InvesterIssuance />
        },
        {
            path: '/icons/material-icons',
            element: <UtilsMaterialIcons />
        },
        {
            path: '/auth/login',
            element: <LoginRoutes />
        },
        {
            path: '/sample-page',
            element: <SamplePage />
        }
    ]
};

export default MainRoutes;
