import { toast } from 'react-toastify';
import axios from './axios-server';

export const fetchAuctionsList = function (page, rowsPerPage, setauctions, setcount) {
    return axios
        .get(`/api/auction/auction-list?page=${page}&limit=${rowsPerPage}`)
        .then((res) => {
            setauctions(res.data.data.rows);
            setcount(res.data.data.count);
        })
        .catch((err) => {
            console.log(err);
        });
};

export const statusFilterAuction = function (page, rowsPerPage, data, setState) {
    axios
        .post(`/api/auction/get-by-status?page=${page}&limit=${rowsPerPage}`, data)
        .then((res) => {
            console.log(res.data);
            setState(res.data.data.rows);
        })
        .catch((err) => {
            console.log(err);
        });
};

export const changeAuctionStatus = function (data, fetchData) {
    return axios
        .post(`/api/auction/edit-auction`, data)
        .then((res) => {
            toast.success(res.data.message,{position:toast.POSITION.TOP_RIGHT})
            fetchData();
        })
        .catch((err) => {
            console.log(err);
        });
};

export const editUser = function (
    Firstname,
    lastName,
    email,
    address,
    phone,
    setloading,
    setOpen,
    user,
    FetchData,
    accreditationVerified,
    status,
    dateOfB
) {
    return axios
        .post(`/api/user/update-2`, {
            first_name: Firstname,
            last_name: lastName,
            email,
            address,
            phoneno: phone,
            id: user.id,
            accreditation_verified: accreditationVerified ?? user.accreditation_verified,
            account_status: status ?? user?.account_status,
            dateOfB
        })
        .then((res) => {
            console.log(res.data);
            FetchData();
            setloading(false);
            setOpen(false);
        })
        .catch((err) => {
            console.log(err);
            setloading(false);
            setOpen(false);
        });
};

export const deleteUser = function (id, FetchData, setOpen) {
    return axios
        .delete(`/api/user/delete-user/${id}`)
        .then((res) => {
            console.log(res.data);
            FetchData();
            setOpen(false);
        })
        .catch((err) => {
            console.log(err);
            setOpen(false);
        });
};
