import axios from 'axios';
import BASE_URL from 'baseurl';

const editUser = (Firstname, lastName, email, address, phone, setloading, setOpen, user, FetchData, accreditationVerified, status,dateOfB) => {
    axios
        .post(`${BASE_URL}/api/user/update-2`, {
            first_name: Firstname,
            last_name: lastName,
            email,
            address,
            phoneno: phone,
            id: user.id,
            accreditation_verified: accreditationVerified ?? user.accreditation_verified,
            account_status: status ?? user?.account_status,
            dateOfB
        })
        .then((res) => {
            console.log(res.data);
            FetchData();
            setloading(false);
            setOpen(false);
        })
        .catch((err) => {
            console.log(err);
            setloading(false);
            setOpen(false);
        });
};

export default editUser;
