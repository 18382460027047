import axios from 'axios';

const filterIssuance = (url, data,setState) => {
    axios
        .post(`${url}`,data)
        .then((res) => {
            console.log(res.data);
            setState(res.data.data.rows);
        })
        .catch((err) => {
            console.log(err);
        });
};

export default filterIssuance;
