// material-ui
import { Typography, Button } from '@mui/material';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import PaymentTable from 'ui-component/PaymentTable';
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import BASE_URL from '../../../baseurl';
import { fetchpayments2 } from 'utils/issuance-routes';

// ==============================|| SAMPLE PAGE ||============================== //
const Payment = () => {
    const [payments, setpayments] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setcount] = useState(0);
    const FetchData = useCallback(() => {
        fetchpayments2(page, rowsPerPage, setpayments, setcount);
    }, []);

    useEffect(() => {
        FetchData();
    }, [FetchData]);

    return (
        <MainCard title="Payment List">
            <PaymentTable
                payments={payments}
                FetchData={FetchData}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                count={count}
                setpayments={setpayments}
                setcount={setcount}
            />
        </MainCard>
    );
};

export default Payment;
