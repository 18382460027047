// //  const BASE_URL = 'https://api.vessel.us'
//  const BASE_URL = 'http://localhost:4000'
//  export default BASE_URL
let API_URL;

if (process.env.REACT_APP_ENV === 'staging') {
    API_URL = 'https://api.exuity.com';
} else if (process.env.REACT_APP_ENV === 'dev') {
     API_URL = 'http://localhost:4000';
    
} else {
  API_URL = process.env.REACT_APP_API_URL || "http://localhost:4000";
}
const BASE_URL = API_URL;

export default BASE_URL;
