import { toast } from 'react-toastify';
import axios from './axios-server';

export const fetchBids = async (page, limit, setbids) => {
    const res = await fetch(`/api/auction/bid-list?page=${page}&limit=${limit}`);
    const data = await res.json();
    setbids(data.data.rows);
    return data.data.rows;
};
export const fetchDash = async (page, rowsPerPage, setbids, setcount) => {
    axios
        .get(`/api/auction/bid-list?page=${page}&limit=${rowsPerPage}`)
        .then((res) => {
            setbids(res.data.data.rows);
            setcount(res.data?.data?.count);
        })
        .catch((err) => {
            console.log(err);
        });
};
export const fetchDashBidId = async (page, rowsPerPage, setbids, setcount,id) => {
    axios
        .get(`/api/auction/bids-auction/${id}?page=${page}&limit=${rowsPerPage}`)
        .then((res) => {
            setbids(res.data.data.rows);
            setcount(res.data?.data?.count);
        })
        .catch((err) => {
            console.log(err);
        });
};

export const editBid = function (amountOffered, Yield, setloading, setOpen, FetchData, status, AmountInvesting,bid,id) {
   return axios
        .post(`/api/auction/edit-bid`, {
            id,
            amount_offered: amountOffered,
            yield:Yield,
            amount_investing:AmountInvesting,
            status: status ?? bid?.status,
        })
        .then((res) => {
            toast.success(res.data.message,{position:toast.POSITION.TOP_RIGHT})
            FetchData();
            setloading(false);
            setOpen(false);
        })
        .catch((err) => {
            console.log(err);
            setloading(false);
            setOpen(false);
        });
};

export const deleteBid = function (id, FetchData, setOpen) {
    return axios
        .delete(`/api/auction/delete-bid/${id}`)
        .then((res) => {
            console.log(res.data);
            FetchData();
            setOpen(false);
        })
        .catch((err) => {
            console.log(err);
            setOpen(false);
        });
};
