// material-ui
import { Typography } from '@mui/material';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import IssuanceTable from 'ui-component/IssuanceTable';
// ==============================|| SAMPLE PAGE ||============================== //
const IssuanceList = () => (
    <MainCard title="Issuances List">
        <IssuanceTable />
    </MainCard>
);

export default IssuanceList;
