import React, { useState, useEffet } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import { Grid, Typography, Box } from '@mui/material';
import Chip from '@mui/material/Chip';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import editUser from 'utils/edit-user';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import editAuction from 'utils/edit-auction';

export default function EditAuctions({ auction, FetchData }) {
    const [loading, setloading] = useState(false);
    const [open, setOpen] = useState(false);
    const [array, setarray] = useState([]);
    const [value, setValue] = useState('');
    const [amountRaising, setAmountRaising] = useState(auction?.amount_raising);
    const [maxYield, setMaxYyield] = useState(auction?.max_yield);
    const [contractLength, setContractLength] = useState(auction?.contract_length);
    const [status, setstatus] = useState(auction?.status);
    const [code, setCode] = useState(auction?.code);
    const dateStart = new Date(auction?.start_date);
    const dateEnd = new Date(auction?.end_date);
    const [startDate, setStartDate] = useState(dateStart);
    const [EndDate, setEndDate] = useState(dateEnd);
    const id = auction.id
    const handleChange = (event) => {
        setstatus(event.target.value);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        setloading(true);
        editAuction(amountRaising, maxYield, contractLength, setloading, setOpen, FetchData, status, code,startDate,EndDate,auction,id);
    };
  
    return (
        <div>
            <IconButton onClick={handleClickOpen}>
                <EditIcon />
            </IconButton>
            <Dialog fullWidth open={open} onClose={handleClose}>
                <DialogTitle sx={{ fontWeight: 'bold' }}>Edit Auction</DialogTitle>
                <DialogContent>
                    <Grid sx={{ marginTop: 1 }} container spacing={2}>
                        <Grid item lg={4} xs={12}>
                            <TextField
                                value={amountRaising}
                                onChange={(e) => setAmountRaising(e.target.value)}
                                id="outlined-basic"
                                label="Amount Raising"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item lg={4} xs={12}>
                            <TextField
                                value={maxYield}
                                onChange={(e) => setMaxYyield(e.target.value)}
                                id="outlined-basic"
                                label="Max Yield"
                                variant="outlined"
                                type="number"
                                inputProps={{ min: 0}}
                            />
                        </Grid>
                        <Grid item lg={4} xs={12}>
                            <TextField
                                value={contractLength}
                                onChange={(e) => setContractLength(e.target.value)}
                                id="outlined-basic"
                                label="Contract Length"
                                variant="outlined"
                                type="number"
                                inputProps={{ min: 0}}
                            />
                        </Grid>
                        {/* <Grid item lg={4} xs={12}>
                            <TextField
                                value={code}
                                onChange={(e) => setCode(e.target.value)}
                                id="outlined-basic"
                                label="Code"
                                variant="outlined"
                            />
                        </Grid> */}

                        <Grid item lg={4} xs={12}>
                            <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={status ?? auction.status}
                                        label="Status"
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="Success">Success</MenuItem>
                                        <MenuItem value="failed">Failed</MenuItem>
                                        <MenuItem value="Inactive">In Active </MenuItem>
                                        <MenuItem value="Active">Active </MenuItem>
                                        <MenuItem value="Under_Review">Under Review </MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>{' '}
                        </Grid>
                        <Grid item lg={4} xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Start Date"
                                    value={startDate}
                                    onChange={(newValue) => {
                                        setStartDate(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item lg={6} xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="End Date"
                                    value={EndDate}
                                    onChange={(newValue) => {
                                        setEndDate(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSubmit}>Update</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
