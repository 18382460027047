// material-ui
import { Typography } from '@mui/material';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import WithDraws from 'ui-component/WithDraws';
// ==============================|| SAMPLE PAGE ||============================== //
const WithDrawsList = () => (
    <MainCard title="WithDraws List">
        <WithDraws  />
    </MainCard>
);

export default WithDrawsList;
