import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import Login from 'views/pages/authentication/authentication3/Login3';

// ==============================|| APP ||============================== //
const App = () => {
    const customization = useSelector((state) => state.customization);
    const userToken = localStorage.getItem('token');
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                {userToken ? (
                    <div>
                        <CssBaseline />
                        <NavigationScroll>
                            <Routes />
                        </NavigationScroll>
                    </div>
                ) : (
                    <Login />
                )}
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
